.headroom-transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .headroom {
    background: transparent !important;
  }

  .headroom--pinned {
    background: #fcc900 !important;
  }
}

.MainBanner {
  height: 100%;
  .main-banner-bg {
    position: relative;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .banner-title {
      font-size: 3rem;
      color: #fff;
      margin-bottom: 5rem;
      font-weight: 700;
      word-break: keep-all;
    }
    .banner-watch-btn {
      background-color: #ffa200;
      color: #fff;
      font-weight: 500;
      font-size: 2rem;
      padding: 1rem 6rem;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 768px) {
  .MainBanner {
    .main-banner-bg {
      .banner-watch-btn {
        width: 100%;
        padding: 1rem 2rem;
      }
    }
  }
}
