.Banner {
  height: 350px;
  background: #fcc900;
  position: relative;

  .banner-wrap {
    padding: 0 10px;
    margin: 0 auto;
    padding-top: 180px;

    .banner-text {
      font-size: 36px;
      font-weight: 500;
      color: #ffffff;
    }

    .banner-img {
      position: absolute;
      right: 11%;
      bottom: -10%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .banner-search {
      margin-top: 3.389830508474576%;
    }
  }
}

@media all and (min-width: 1200px) {
  .Banner {
    .banner-wrap {
      width: 1180px;
    }
  }
}

@media all and (max-width: 1199px) {
  .Banner {
    overflow: hidden;

    .banner-wrap {
      .banner-img {
        right: -14%;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .Banner {
    .banner-wrap {
      .banner-text {
        font-size: 30px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .Banner {
    height: 350px;
    text-align: center;
    overflow: visible;

    .banner-wrap {
      padding-top: 120px;

      .banner-text {
        font-size: 24px;
      }
      .banner-img {
        right: 0;
        left: 0;
      }
    }
  }
}
