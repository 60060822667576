.Download {
  padding: 0 1rem;
  margin: 4rem auto;
  .download-headline {
    margin-top: 80px;
    padding-bottom: 10px;

    p {
      font-size: 30px;
      font-weight: 500;
      color: #333333;
    }
  }

  .download-wrap {
    border-top: 3px solid #f19a02;
    padding-top: 40px;
    display: flex;

    .download-pc {
      width: 50%;
      padding-right: 10px;

      .download-pc-button {
        button {
          width: 100%;
          border: none;
          background: #fc8200;
          border-radius: 10px;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          color: #ffffff;
          height: 100px;
          padding: 0 5%;
          cursor: pointer;

          &:hover {
            background: #f19a02;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
      }

      .download-pc-label {
        margin-top: 5px;
        font-size: 16px;
        color: #555555;
      }
    }

    .download-hmd {
      width: 50%;
      padding-left: 10px;

      .download-hmd-button {
        button {
          width: 100%;
          border: none;
          background: #2fae8e;
          border-radius: 10px;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          color: #ffffff;
          height: 100px;
          padding: 0 5%;
          cursor: pointer;

          &:hover {
            background: #219376;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
      }

      .download-hmd-label {
        margin-top: 5px;
        font-size: 16px;
        color: #555555;
      }
    }
  }
}

@media all and (min-width: 1200px) {
  .Download {
    width: 1180px;
  }
}

@media all and (max-width: 1024px) {
  .Download {
    .download-wrap {
      flex-direction: column;

      .download-pc {
        width: 100%;
        padding: 0 10px;
      }

      .download-hmd {
        width: 100%;
        padding: 0 10px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .Download {
    .download-headline {
      p {
        font-size: 24px;
        text-align: center;
      }
    }

    .download-wrap {
      flex-direction: column;

      .download-pc {
        width: 100%;
        padding: 0 10px;

        .download-pc-label {
          font-size: 12px;
        }

        .download-pc-button {
          button {
            font-size: 20px;
            height: 80px;

            img {
              width: 30px;
            }
          }
        }
      }

      .download-hmd {
        width: 100%;
        padding: 0 10px;
        margin-top: 3%;

        .download-hmd-label {
          font-size: 12px;
        }

        .download-hmd-button {
          button {
            font-size: 20px;
            height: 80px;

            img {
              width: 30px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .Download {
    .download-headline {
      p {
        font-size: 20px;
      }
    }

    .download-wrap {
      .download-pc {
        .download-pc-button {
          button {
            font-size: 16px;

            img {
              width: 25px;
            }
          }
        }
      }

      .download-hmd {
        .download-hmd-button {
          button {
            font-size: 16px;

            img {
              width: 25px;
            }
          }
        }
      }
    }
  }
}
